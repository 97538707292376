import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Stack,
  Typography
} from '@mui/material';
import Avatar from 'components/Avatar';
import { useCursorPaginationContext } from 'contexts/CursorPaginationContext';
import { useAuth } from 'hooks/useAuth';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetAnnotationCommentsQuery } from 'services/annotation';
import { getMentionText } from 'utils/mentionHelper';
import AnnotationCommentBox from './CommentBox';
import AnnotationCommentDeleteButton from './CommentDeleteButton';

const PAGE_SIZE = 2;

const AnnotationCommentList = ({ annotationId, editId, enableEdit, disableEdit }) => {
  const [searchParams] = useSearchParams();
  const commentId = searchParams.get('comment');
  const {
    user: { _id: uid }
  } = useAuth();
  const { collection, setCollection } = useCursorPaginationContext();

  const [cursor, setCursor] = useState('');
  const {
    data: paginatedData,
    isLoading,
    isFetching
  } = useGetAnnotationCommentsQuery(
    annotationId
      ? {
          id: annotationId,
          cursor,
          limit: PAGE_SIZE
        }
      : undefined
  );
  const commentRef = useRef(null);

  const disableEditButton = (
    <IconButton size="small" color="buttonGray" onClick={disableEdit}>
      <CancelIcon fontSize="xs" />
    </IconButton>
  );

  useEffect(() => {
    if (!isFetching) {
      if (paginatedData?.list) {
        setCollection((prevState) => {
          if (!cursor) return paginatedData.list;
          return [...prevState, ...paginatedData.list];
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, paginatedData?.list, cursor]);

  useEffect(() => {
    if (commentId && commentRef.current && !isLoading) {
      const timeout = setTimeout(() => {
        commentRef.current.scrollIntoView({
          behavior: 'smooth'
        });
        commentRef.current = null;
      }, 400);
      return () => clearTimeout(timeout);
    }
  }, [commentRef?.current, commentId, isLoading]);

  if (isLoading) {
    return <LinearProgress />;
  }

  const renderActionButtons = (comment) => {
    if (uid !== comment.createdBy?._id) return null;
    if (editId && editId === comment._id) {
      return disableEditButton;
    }
    return (
      <Stack flexDirection="row">
        <IconButton
          size="small"
          color="buttonGray"
          onClick={() => enableEdit(comment._id)}
        >
          <EditIcon fontSize="xs" />
        </IconButton>
        <AnnotationCommentDeleteButton
          annotationId={annotationId}
          commentId={comment._id}
        />
      </Stack>
    );
  };

  const renderLoadMore = () => {
    if (isFetching) {
      return <LinearProgress />;
    }
    if (paginatedData?.next) {
      return (
        <Button
          sx={{ textTransform: 'none' }}
          size="small"
          onClick={() => setCursor(paginatedData.next)}
        >
          Load older comments
        </Button>
      );
    }
    return null;
  };

  return (
    <Stack spacing={1}>
      {collection?.map((comment) => (
        <Stack key={comment._id} spacing={0.5}>
          {comment._id === commentId && (
            <Box position="relative" top={-150} ref={commentRef} />
          )}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1}>
              <Avatar src={comment.createdBy?.avatar} size={40} />
              <Stack>
                <Typography fontWeight={500} fontSize="0.9rem">
                  {`${comment.createdBy.firstName} ${comment.createdBy.lastName}`.trim()}
                </Typography>
                <Typography variant="body2" fontSize="0.75rem">
                  {moment(comment.createdAt).fromNow()}
                </Typography>
              </Stack>
            </Stack>
            {renderActionButtons(comment)}
          </Stack>
          <Stack>
            {editId !== comment._id ? (
              <Typography
                variant="body1"
                fontSize="0.85rem"
                whiteSpace="pre-wrap"
                sx={{
                  wordBreak: 'break-word'
                }}
              >
                {getMentionText(comment.content)}
              </Typography>
            ) : (
                <AnnotationCommentBox
                annotationId={annotationId}
                comment={comment}
                onSuccess={disableEdit}
              />
            )}
          </Stack>
        </Stack>
      ))}
      {renderLoadMore()}
    </Stack>
  );
};

export default AnnotationCommentList;
