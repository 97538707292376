import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import * as React from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsOverscanIcon from '@mui/icons-material/SettingsOverscan';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import Accordion from 'components/Accordion';
import { getPublicImage } from '../../../utils';
import AnnotationComment from './AnnotationComment';
import { ChatGPTComment } from './ChatGPT';
import SlideComment from './SlideComment';

const tibyimage = getPublicImage('tiby.svg');
const drawerWidth = 350;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflow: 'hidden',
  '& .slide-manager__header-text': {
    display: 'block'
  }
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflow: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`
  },
  '& .slide-manager__header-text': {
    display: 'none'
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  '& .MuiPaper-root': {
    position: 'static'
  },

  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '&.MuiDrawer-docked': {
      width: '550px'
    },
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      width: '550px'
    }
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

const SlideRightPanel = ({
  slideId,
  open,
  handleToggle,
  selectedAnnotation
}) => {
  const [openTibyModal, setOpenTibyModal] = React.useState(false);

  const hasAnnotation = selectedAnnotation?._id;

  const handleOpenTibyModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenTibyModal(true);
  }

  const handleCloseTibyModal = () => {
    setOpenTibyModal(false);
  }

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={() => handleToggle(!open)} size="small">
          {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
        {open && (
          <Typography
            sx={{
              width: '100%',
              textAlign: 'left'
            }}
            variant="h6"
          >
            Comments
          </Typography>
        )}
      </DrawerHeader>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          height: 'calc(100% - 64px)',
          borderTop: 1,
          borderColor: 'divider',
          overflowX: 'hidden',
          overflowY: 'auto'
        }}
      >
        <Accordion
          heading="Slide Comment"
          borderBottom
          padding={false}
          callback={(isOpen) => {
            if (isOpen) handleToggle(true);
          }}
        >
          <Stack display={open ? 'block' : 'none'}>
            <SlideComment slideId={slideId} />
          </Stack>
        </Accordion>
        {hasAnnotation && (
          <Accordion
            heading={
              <Stack direction="row" gap={1} alignItems="center">
                Chat with <img src={tibyimage} alt='Tiby' style={{ width: '36px', height: '36px', marginRight: '8px', verticalAlign: 'middle' }} />
                <IconButton onClick={handleOpenTibyModal}>
                  <SettingsOverscanIcon />
                </IconButton>
              </Stack>
            }
            defaultOpen={true}
            borderBottom
            padding={false}
            callback={(isOpen) => {
              if (isOpen) handleToggle(true);
            }}
            openFullHeight
          >
            {open && (
              <ChatGPTComment slideId={slideId} groupId = {selectedAnnotation.group}/>
            )}
          </Accordion>
        )}
        {hasAnnotation && (
          <Accordion
            heading={`Annotation Comment`}
            borderBottom
            padding={false}
            callback={(isOpen) => {
              if (isOpen) handleToggle(true);
            }}
            openFullHeight
          >
            <Stack display={open ? 'block' : 'none'}>
              <AnnotationComment annotationId={selectedAnnotation._id} />
            </Stack>
          </Accordion>
        )}
      </Box>
      <Dialog
        open={openTibyModal}
        onClose={handleCloseTibyModal}
        maxWidth="xl"
        fullWidth
        sx={{
          '& .MuiPaper-root': {
            height: '100%',
          },
        }}
      >
        <DialogContent>
          <ChatGPTComment slideId={slideId} groupId = {selectedAnnotation.group}/>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="buttonLightGray"
            disableElevation
            onClick={handleCloseTibyModal}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  );
};
export default SlideRightPanel;
