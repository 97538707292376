import { Box, Checkbox, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Accordion from 'components/Accordion';
import { SlideViewerContext } from 'hooks/useSlideViewerContext';
import { useContext, useState } from 'react';
import { MCDLayerColor, MCDLayerType } from 'types/MCDTypes';

type MCDLayerProps = {
    defaultOpen?: boolean;
};
const MCDLayers = ({ defaultOpen }: MCDLayerProps) => {
    const [ selectedAll, setSelectedAll ] = useState(false);
    const [ state, dispatch ] = useContext(SlideViewerContext);
    const { MCDLayers: options = [] } = state;

    const handleSelectOption = (index: number) => {
        dispatch({ type: 'TOGGLE_MCD_LAYER_SELECT', payload: index });
    };

    const handleColorChange = (index: number, color: MCDLayerColor) => {
        dispatch({ type: 'UPDATE_MCD_LAYER_COLOR', payload: { index, color } });
    };

    const handleSelectAll = () => {
        if (selectedAll) {
            handleDeSelectAll();
            setSelectedAll(false);
            return;
        }
        dispatch({ type: 'SELECT_ALL_MCD_LAYERS' });
        setSelectedAll(true);
    };

    const handleDeSelectAll = () => {
        dispatch({ type: 'DESELECT_ALL_MCD_LAYERS' });
    };

    const sortedOptions = options.map((option: any, index: any) => ({ 
        ...option, 
        originalIndex: index 
    })).sort((a: { selected: any; channel: string }, b: { selected: any; channel: string }) => {
        return a.channel.localeCompare(b.channel);
    });

    return (
        <Accordion
            unmountOnExit={ true }
            heading="Layers"
            defaultOpen={ true }
            disabled={ false }>
            { options.length === 0 ? (
                <Box sx={ { textAlign: 'center', color: 'text.secondary', p: 2 } }>
                    No layers available
                </Box>
            ) : (
                <TableContainer component={ Paper }>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="none" align='center'>
                                    <Checkbox
                                        size="small"
                                        name='all'
                                        aria-checked={ selectedAll }
                                        checked={ selectedAll }
                                        onChange={ handleSelectAll } />
                                </TableCell>
                                <TableCell padding="none">Channel</TableCell>
                                <TableCell padding="none">Label</TableCell>
                                <TableCell padding="none">Color</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                { sortedOptions.map((option: MCDLayerType) => (
                                <TableRow key={ option.originalIndex }>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            size="small"
                                            checked={ option.selected || false }
                                            aria-checked={ option.selected || false }
                                            onChange={ () => handleSelectOption(option.originalIndex) }
                                        />
                                    </TableCell>
                                    <TableCell padding="none">{ option.channel }</TableCell>
                                    <TableCell padding="none">{ option.label }</TableCell>
                                    <TableCell padding="none">
                                        <Select
                                            size="small"
                                            value={ option.color || MCDLayerColor.WHITE }
                                            onChange={ (e) => handleColorChange(option.originalIndex as number, e.target.value as MCDLayerColor) }
                                            renderValue={ (selected) => (
                                                <span
                                                    style={ {
                                                        display: 'inline-block',
                                                        width: 20,
                                                        height: 20,
                                                        backgroundColor: selected,
                                                        borderRadius: '4px'
                                                    } }
                                                />
                                            ) }
                                        >
                                            { Object.values(MCDLayerColor).map((color) => (
                                                <MenuItem key={ color } value={ color }>
                                                    <span
                                                        style={ {
                                                            display: 'inline-block',
                                                            width: 20,
                                                            height: 20,
                                                            backgroundColor: color,
                                                            marginRight: 8,
                                                            borderRadius: '4px'
                                                        } }
                                                    />
                                                    { color.charAt(0).toUpperCase() + color.slice(1) }
                                                </MenuItem>
                                            )) }
                                        </Select>
                                    </TableCell>
                                </TableRow>
                            )) }
                        </TableBody>
                    </Table>
                </TableContainer>
            ) }
        </Accordion>
    );
};

export default MCDLayers;