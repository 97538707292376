import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GridViewIcon from '@mui/icons-material/GridView';
import Logout from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  SvgIconTypeMap,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';
import { useAuth } from 'hooks/useAuth';
import { forwardRef, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPublicImage } from 'utils';
import { ROLE_NAMES, ROLES } from '../../constants';
import { removeCredentials } from '../../stores/auth/authSlice';
import { removePaginationAndFilters, resetState } from '../../stores/slidebox/slideboxSlice';
import NotificationMenu from './Notification/NotificationMenu';
import UserGuide from './UserGuide';

type MenuItemType = {
  text: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  to: string;
};
const CustomAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth'
})<{
  open?: boolean;
  drawerWidth?: number;
}>(({ theme, open, drawerWidth }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create([ 'width', 'margin' ], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create([ 'width', 'margin' ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  color: theme.palette.primary.main,
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

const createMenus = (role: string) => {
  let menus: MenuItemType[] = [];
  if (role !== ROLES.SUPER_ADMIN) {
    menus = [
      {
        text: 'Slidebox',
        icon: GridViewIcon,
        to: '/slidebox'
      },
      {
        text: 'Groups',
        icon: WorkspacesOutlinedIcon,
        to: '/groups'
      },
      // {
      //   text: 'Analysis',
      //   icon: ShowChartIcon,
      //   to: '/analysis'
      // }
    ];
  }
  if (role === ROLES.SUPER_ADMIN || role === ROLES.ADMIN) {
    menus.unshift({
      text: 'Dashboard',
      icon: SettingsIcon,
      to: '/dashboard'
    });
  }
  return menus;
};

type AppBarProps = {
  open: boolean;
  drawerWidth: number;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
};

const AppBar = forwardRef<HTMLDivElement, AppBarProps>(
  ({ open, drawerWidth, handleDrawerOpen, handleDrawerClose }, ref) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { user } = useAuth();
    const dispatch = useDispatch();
    const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>(null);
    const dropdown = Boolean(anchorEl);
    const openProfileDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const menus = useMemo(() => createMenus(user.role), [ user.role ]);

    const logout = () => {
      dispatch(removePaginationAndFilters());
      dispatch(removeCredentials());
      dispatch(resetState({}));
      navigate('/login');
      setAnchorEl(null);
    };

    const profile = () => {
      navigate('/profile');
    };

    return (
      <CustomAppBar
        ref={ ref }
        drawerWidth={ drawerWidth }
        position="fixed"
        open={ open }
        sx={ { backgroundColor: '#F4F5FC', boxShadow: 'none' } }
        id="app-bar"
      >
        {/* <IconButton
          onClick={ () => handleDrawerOpen() }
          disableRipple={ true }
          sx={ {
            padding: '10px',
            top: '0',
            position: 'absolute',
            zIndex: 1
          } }
        >
          <MenuIcon
            sx={ {
              fontSize: '50px',
              color: open ? theme.palette.secondary.main : theme.palette.primary.main
            } }
          />
        </IconButton> */}
        <Toolbar
          sx={ {
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
            gap: 2
          } }
        >
          <Box sx={ { display: 'flex', gap: 2 } }>
            <Box
              sx={ {
                display: 'block',
                margin: open ? '0px auto' : '',
                cursor: 'pointer'
              } }
            >
              <Box
                onClick={ () => window.open('https://www.targetedbioscience.com/', '_blank') }
                sx={ {
                  justifyContent: 'initial',
                  display: 'flex',
                  padding: '0px 10px',
                  alignItems: 'center'
                } }
              >
                <img
                  alt="logo"
                  width="50"
                  src={ getPublicImage('logo.png') }
                  style={ { marginRight: '12px' } }
                />
                <ListItemText
                  sx={ {
                    '& .MuiTypography-root': {
                      fontSize: '24px!important',
                      fontWeight: 600,
                      lineHeight: '24px',
                      color: theme.palette.primary.main
                    }
                  } }
                >
                  Targeted
                  <br />
                  Bioscience
                </ListItemText>
              </Box>
            </Box>
            {menus.map((item) => (
              <AnimatedButton
                key={item.text}
                onClick={() => navigate(item.to)}
                startIcon={<item.icon sx={{ fontSize: '28px', color: theme.palette.primary.main }} />}
              >
                {item.text}
              </AnimatedButton>
            ))}
          </Box>
          <Box sx={ { display: 'flex', alignItems: 'center', gap: 2 } }>
            <UserGuide />
            <NotificationMenu />
            <IconButton onClick={ openProfileDropdown }>
              { !user.avatarURL && <AccountCircleIcon
                sx={ {
                  fontSize: '50px',
                  color: theme.palette.primary.main
                } }
              /> }
              { user.avatarURL && <img
                src={ user.avatarURL }
                alt="avatar"
                style={ {
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  objectFit: 'cover'
                } }
              /> }
            </IconButton>
          </Box>
        </Toolbar>
        <Menu
          anchorEl={ anchorEl }
          id="account-menu"
          open={ dropdown }
          onClose={ handleClose }
          onClick={ handleClose }
          slotProps={ {
            paper: {
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }
          } }
          transformOrigin={ { horizontal: 'right', vertical: 'top' } }
          anchorOrigin={ { horizontal: 'right', vertical: 'bottom' } }
        >
          <Box
            sx={ {
              display: 'flex',
              flexDirection: 'column',
              gap: 0.5,
              px: 2,
              pb: 2
            } }
          >
            <Typography variant="h6">{ `${user.firstName} ${user.lastName}` }</Typography>
            <Typography variant="body2">{ `Email: ${user.email}` }</Typography>
            <Typography
              variant="body2"
              paragraph={ true }
              mb={ 0 }
            >{ `Organization: ${user.organization.name}` }</Typography>
            <Typography variant="body2" paragraph={ true } mb={ 0 }>{ `Role: ${ROLE_NAMES[ user.role as keyof typeof ROLE_NAMES ]}` }</Typography>
          </Box>
          <Divider
            sx={ {
              mb: 1
            } }
          />
          <MenuItem onClick={ profile }>
            <ListItemIcon>
              <AccountBoxIcon fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
          <Divider />
          <MenuItem onClick={ logout }>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </CustomAppBar>
    );
  }
);
export default AppBar;
