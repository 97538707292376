import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import * as React from 'react';
import {
  DrawerContextProvider,
  useDrawerContext
} from '../../contexts/DrawerContext';
import AppBar from '../Appbar';

function CustomAppDrawer({ children }) {
  const { open, handleDrawerOpen, handleDrawerClose, drawerWidth } =
    useDrawerContext();
  // const theme = useTheme();
  // const smAndUp = useMediaQuery(theme.breakpoints.up('sm'));
  // const IconComponent = React.useMemo(
  //   () => (open ? ChevronLeft : ChevronRight),
  //   [open]
  // );
  // const {
  //   user: { role }
  // } = useAuth();
  const [paddingFromNav, setPaddingFromNav] = React.useState(0);

  const appBarRef = React.useRef(null);

  React.useLayoutEffect(() => {
    if (appBarRef.current) {
      const appBarHeight = appBarRef.current.offsetHeight;
      setPaddingFromNav(appBarHeight);
    }
  }, [appBarRef]);

  // const createMenus = (role) => {
  //   let menus = []
  //   if (role !== ROLES.SUPER_ADMIN) {
  //     menus = [
  //       {
  //         text: 'Slidebox',
  //         icon: GridViewIcon,
  //         to: '/slidebox'
  //       },
  //       {
  //         text: 'Groups',
  //         icon: WorkspacesOutlinedIcon,
  //         to: '/groups'
  //       },
  //       {
  //         text: 'Analysis',
  //         icon: ShowChartIcon,
  //         to: '/analysis'
  //       }
  //     ];
  //   }
  //   if (role === ROLES.SUPER_ADMIN || role === ROLES.ADMIN) {
  //     menus.unshift({
  //       text: 'Dashboard',
  //       icon: SettingsIcon,
  //       to: '/dashboard'
  //     });
  //   }
  //   return menus;
  // };

  // const menus = React.useMemo(() => createMenus(role), [role]);

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        ref={appBarRef}
        drawerwidth={drawerWidth}
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
      <CssBaseline />
      <Box sx={{ position: 'relative', zIndex: '1202' }}>
        {/* <AppDrawer
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          open={open}
          drawerwidth={drawerWidth}
          menus={menus}
        /> */}
        {/* {smAndUp && (
          <IconButton
            onClick={() => (open ? handleDrawerClose() : handleDrawerOpen())}
            disableRipple={true}
            sx={{
              padding: '0px',
              position: 'absolute',
              right: '-12px',
              top: '65px',
              transform: 'translateY(-28px)',
              borderRadius: '50px',
              background: '#fff',
              zIndex: '1300',
              boxShadow:
                'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
            }}
          >
            <IconComponent
              sx={{
                fontSize: '28px'
              }}
            />
          </IconButton>
        )} */}
      </Box>

      <Box
        component="main"
        sx={{
          minHeight: '100vh',
          backgroundColor: '#F8F8F8',
          width: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: `${paddingFromNav}px`
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default function Layout({ children }) {
  return (
    <DrawerContextProvider>
      <CustomAppDrawer>{children}</CustomAppDrawer>
    </DrawerContextProvider>
  );
}
