import { Box, Stack } from '@mui/material';
import CommentList from './CommentList';
import CommentBox from './CommentBox';
import useToggleState from 'hooks/useToggleState';

/**
 * @param {string} slideId
 * @returns {JSX.Element}
 * @constructor
 */
const SlideComment = ({ slideId }) => {
  const [editId, enableEdit, disableEdit] = useToggleState(null);

  return (
    <Stack
      sx={{
        overflowY: 'auto',
        height: '100%'
      }}
      spacing={1}
      p={1}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          width: '100%',
          position: 'relative',
          height: '100%',
          overflow: 'hidden'
        }}
      >
        {!editId && <CommentBox slideId={slideId} />}
        <CommentList 
          slideId={slideId} 
          editId={editId} 
          enableEdit={enableEdit} 
          disableEdit={disableEdit} 
        />
      </Box>
    </Stack>
  );
};

export default SlideComment;
