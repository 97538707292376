import { Box, Stack } from '@mui/material';
import { CursorPaginationContextProvider } from 'contexts/CursorPaginationContext';
import useToggleState from 'hooks/useToggleState';
import AnnotationCommentBox from './CommentBox';
import AnnotationCommentList from './CommentList';

const AnnotationComment = ({ annotationId }) => {
  const [editId, enableEdit, disableEdit] = useToggleState(null);

  return (
    <CursorPaginationContextProvider>
      <Stack
        sx={{
          overflowY: 'auto',
          height: '100%'
        }}
        spacing={1}
        p={1}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            width: '100%',
            position: 'relative',
            height: '100%',
            overflow: 'hidden'
          }}
        >
          {!editId && <AnnotationCommentBox annotationId={annotationId} />}
          <AnnotationCommentList
            annotationId={annotationId}
            editId={editId}
            enableEdit={enableEdit}
            disableEdit={disableEdit}
          />
        </Box>
      </Stack>
    </CursorPaginationContextProvider>
  );
};

export default AnnotationComment;
