import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';

import Label from 'v2/components/label';
import { useSnackbar } from 'v2/components/snackbar';
import FormProvider, { RHFTextField, RHFSelect, RHFUploadAvatar } from 'v2/components/hook-form';

import { EUserRole, IUser } from 'types/user';
import { USER_ROLES_OPTIONS, getUserStatusColor } from 'v2/utils/user';
import { Avatar, MenuItem } from '@mui/material';
import { useAuth } from 'hooks/useAuth';
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation
} from 'services/user';
import { UserSchema } from 'v2/shared/schema/user';
import { useBoolean } from 'v2/hooks/use-boolean';
import { ConfirmDialog } from 'v2/components/custom-dialog';
import { handleBackendError } from 'v2/utils/handle-backend-error';
import { useUploadImageMutation } from 'services/upload';
import { useDispatch } from 'react-redux';
import { updateUser as updateAuthUser } from 'stores/auth/authSlice';

// ----------------------------------------------------------------------

type Props = {
  currentUser?: IUser;
  onCallback?: VoidFunction;
  onDeleted?: VoidFunction;
};

export default function UserNewEditForm({
  currentUser,
  onCallback,
  onDeleted
}: Props) {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const confirm = useBoolean();
  const [ updateUser, { isLoading: isUpdatingUser } ] = useUpdateUserMutation();
  const [ createUser, { isLoading: isCreateUser } ] = useCreateUserMutation();
  const [ deleteUser, { isLoading: isDeleting } ] = useDeleteUserMutation();
  const [ preview, setPreview ] = useState<string | undefined>(undefined);
  const [ showUpload, setShowUpload ] = useState(false);
  const [ , setAvatarFile ] = useState<File | null>(null);
  const [ uploadImage ] = useUploadImageMutation();

  const isAdmin = useMemo(() => {
    return (
      user?.role === EUserRole.ADMIN || user?.role === EUserRole.SUPER_ADMIN
    );
  }, [ user ]);

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () => ({
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      // city: currentUser?.city || '',
      role: currentUser?.role || EUserRole.USER,
      email: currentUser?.email || '',
      // state: currentUser?.state || '',
      // status: currentUser?.status || EUserStatus.ACTIVE,
      // address: currentUser?.address || '',
      // country: currentUser?.country || '',
      // zipCode: currentUser?.zipCode || '',
      avatarURL: currentUser?.avatarURL || null,
      // phone: currentUser?.phone || '',
      isActive: currentUser?.isActive || false,
      // isVerified: currentUser?.isVerified || false
    }),
    [ currentUser ]
  );

  const methods = useForm({
    resolver: yupResolver(UserSchema),
    defaultValues
  });

  const {
    watch,
    control,
    // setValue,
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { isActive, avatarURL, ...rest } = data;
      const payload = {
        ...rest,
        is_active: isActive,
      };

      if (currentUser) {
        try {
          const userResponse = await updateUser({
            id: currentUser._id,
            ...payload
          }).unwrap();

          if (currentUser._id === user?.id) {
            dispatch(updateAuthUser(userResponse));
          }

          enqueueSnackbar('User updated successfully!', { variant: 'success' });
          return userResponse;
        } catch (error) {
          console.error('Failed to update user:', error);
          throw error;
        }
      } else {
        await createUser(payload);
      }
      setShowUpload(false);

      enqueueSnackbar(currentUser ? 'Update success!' : 'Create success!', {
        variant: 'success'
      });
      if (onCallback) onCallback();
    } catch (error) {
      enqueueSnackbar(
        handleBackendError(error).message || 'Something went wrong',
        {
          variant: 'error'
        }
      );
    }
  });

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[ 0 ];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file)
      });

      const filePreviewUrl = newFile.preview;
      setPreview(filePreviewUrl);
      setAvatarFile(file);

      if (file && currentUser) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('folder', 'avatar-profile');
        const result = await uploadImage(formData).unwrap();
        const finalAvatarUrl = result.url;
        setPreview(finalAvatarUrl);
        await updateUser({
          id: currentUser._id,
          ...currentUser,
          avatarURL: finalAvatarUrl
        }).unwrap();
        setShowUpload(false);
        dispatch(updateAuthUser({ ...currentUser, avatarURL: finalAvatarUrl }));
        enqueueSnackbar('Avatar updated successfully!', {
          variant: 'success'
        });
      }
    },
    [ currentUser, dispatch, enqueueSnackbar, updateUser, uploadImage ]
  );

  useEffect(() => {
    // Cleanup preview URL when component unmounts
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [ preview ]);

  const onDelete = async () => {
    try {
      const response = await deleteUser(currentUser?._id).unwrap();
      if (response.success) {
        enqueueSnackbar('Delete the user successfully', {
          variant: 'success'
        });
        setTimeout(() => {
          if (onDeleted) onDeleted();
        }, 100);
      }
    } catch (error) {
      enqueueSnackbar(
        handleBackendError(error).message || 'Something went wrong',
        {
          variant: 'error'
        }
      );
    }
  };

  return (
    <>
      <FormProvider methods={ methods } onSubmit={ onSubmit }>
        <Grid container spacing={ 3 }>
          <Grid xs={ 12 } md={ 4 }>
            <Card
              sx={ {
                pt: 10,
                pb: 5,
                px: 3,
                position: 'relative'
              } }
            >
              { currentUser && (
                <Box
                  sx={ {
                    position: 'absolute',
                    top: 24,
                    right: 24,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: 1
                  } }
                >
                  <Label color={ getUserStatusColor(values.isActive) }>
                    { values.isActive ? 'Active' : 'Disabled' }
                  </Label>

                  {/* <Label color={getUserStatusColor(values.isVerified)}>
                  {values.isVerified ? 'Verified' : 'Unverified'}
                </Label> */}
                </Box>
              ) }

              <Box sx={ { mb: 5 } }>
                <Stack direction="row" spacing={ 2 } alignItems="center" justifyContent="center">
                  { showUpload && (
                    <Box>
                      <RHFUploadAvatar
                        name="avatarURL"
                        maxSize={ 3145728 }
                        onDrop={ handleDrop }
                        thumbnail={ true }
                      // helperText={
                      //   <Typography
                      //     variant="caption"
                      //     sx={{
                      //       mt: 3,
                      //       mx: 'auto',
                      //       display: 'block',
                      //       textAlign: 'center',
                      //       color: 'text.disabled'
                      //     }}
                      //   >
                      //     Allowed *.jpeg, *.jpg, *.png, *.gif
                      //     <br /> max size of {fData(3145728)}
                      //   </Typography>
                      // }
                      />
                    </Box>
                  ) }

                  { !showUpload && <Box
                    sx={ {
                      mx: 'auto',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      p: 1,
                      width: 144,
                      height: 144,
                      borderRadius: '50%',
                      border: (theme) => `1px dashed ${theme.palette.divider}`
                    } }
                  >
                    <Avatar
                      color="primary"
                      sx={ { width: '100%', height: '100%' } }
                      src={ preview || currentUser?.avatarURL }
                    />
                  </Box> }
                </Stack>
              </Box>

              { currentUser && isAdmin && (
                <>
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Controller
                        name="isActive"
                        control={ control }
                        render={ ({ field }) => (
                          <Switch
                            { ...field }
                            checked={ field.value }
                            onChange={ (event) => {
                              field.onChange(event.target.checked);
                            } }
                          />
                        ) }
                      />
                    }
                    label={
                      <>
                        <Typography variant="subtitle2" sx={ { mb: 0.5 } }>
                          Active
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={ { color: 'text.secondary' } }
                        >
                          Apply disable account
                        </Typography>
                      </>
                    }
                    sx={ {
                      mx: 0,
                      mb: 3,
                      width: 1,
                      justifyContent: 'space-between'
                    } }
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={ showUpload }
                        onChange={ (e) => setShowUpload(e.target.checked) }
                      />
                    }
                    label="Change Avatar"
                    sx={ { mb: 2 } }
                  />
                  {/* <RHFSwitch
                  name="isVerified"
                  labelPlacement="start"
                  disabled={!isAdmin}
                  label={
                    <>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Email Verified
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: 'text.secondary' }}
                      >
                        Disabling this will automatically send the user a
                        verification email
                      </Typography>
                    </>
                  }
                  sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                /> */}
                  { currentUser._id !== user?.id && (
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={ { mt: 3 } }
                    >
                      <Button
                        variant="contained"
                        onClick={ confirm.onTrue }
                        disabled={ isUpdatingUser || isDeleting }
                        color="error"
                      >
                        Delete User
                      </Button>
                    </Stack>
                  ) }
                </>
              ) }
            </Card>
          </Grid>

          <Grid xs={ 12 } md={ 8 }>
            <Card sx={ { p: 3 } }>
              <Box
                rowGap={ 3 }
                columnGap={ 2 }
                display="grid"
                gridTemplateColumns={ {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)'
                } }
              >
                <RHFTextField name="firstName" label="First Name" />
                <RHFTextField name="lastName" label="Last Name" />
                <RHFTextField name="email" label="Email Address" />
                {/* <RHFTextField name="phone" label="Phone Number" /> */ }
                {/* <RHFAutocomplete
                name="country"
                label="Country"
                options={countries.map((country) => country.label)}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                renderOption={(props, option) => {
                  const { code, label, phone } = countries.filter(
                    (country) => country.label === option
                  )[0];

                  if (!label) {
                    return null;
                  }

                  return (
                    <li {...props} key={label}>
                      <Iconify
                        key={label}
                        icon={`circle-flags:${code.toLowerCase()}`}
                        width={28}
                        sx={{ mr: 1 }}
                      />
                      {label} ({code}) +{phone}
                    </li>
                  );
                }}
              /> */}

                {/* <RHFTextField name="state" label="State/Region" /> */ }
                {/* <RHFTextField name="city" label="City" /> */ }
                {/* <RHFTextField name="address" label="Address" /> */ }
                {/* <RHFTextField name="zipCode" label="Zip/Code" /> */ }
                { user?.role === EUserRole.SUPER_ADMIN && (
                  <>
                    <RHFSelect name="role" label="Role">
                      { USER_ROLES_OPTIONS.map((role) => (
                        <MenuItem key={ role.value } value={ role.value }>
                          { role.label }
                        </MenuItem>
                      )) }
                    </RHFSelect>
                  </>
                ) }
              </Box>

              <Stack alignItems="flex-end" sx={ { mt: 3 } }>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={
                    isSubmitting || isUpdatingUser || isCreateUser || isDeleting
                  }
                >
                  { !currentUser ? 'Create User' : 'Save Changes' }
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
      <ConfirmDialog
        open={ confirm.value }
        onClose={ confirm.onFalse }
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <LoadingButton
            loading={ isDeleting }
            variant="contained"
            color="error"
            onClick={ onDelete }
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
}
