import { Box } from '@mui/material';
import { useGroupsContext } from 'contexts/GroupsContext';
import SubDrawer from '../../../components/Drawer/SubDrawer';
import { useDebounce } from '../../../hooks/useDebounce';
import useToggleState from '../../../hooks/useToggleState';
import GroupList from './GroupList';
import SearchBox from './SearchBox';
import Toolbar from './Toolbar';

const GroupSubDrawer = ( { keyword, setKeyword } ) => {
  const { setFilter } = useGroupsContext();
  const [ open, openDrawer, closeDrawer ] = useToggleState( true );
  const debouncedFilter = useDebounce( ( value ) => {
    setFilter( value );
  }, 300 );

  const handleSearch = ( value ) => {
    setKeyword( value );
    debouncedFilter( value );
  };

  return (
    <SubDrawer open={true} drawerWith={0}>
      <Box sx={{ p: 2, minWidth: 74 }}>
        <Toolbar
          expanded={open}
          onExpand={openDrawer}
          onCollapse={closeDrawer}
        />
        <SearchBox
          expanded={open}
          onExpand={openDrawer}
          value={keyword}
          onSubmit={handleSearch}
        />
        <GroupList expanded={open} />
      </Box>
    </SubDrawer>
  );
};

export default GroupSubDrawer;
