import { Avatar, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useGroupsContext } from 'contexts/GroupsContext';
import { webSocketType } from 'contexts/WebSocketContext';
import { useAuth } from 'hooks/useAuth';
import useToggleState from 'hooks/useToggleState';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getMentionText } from 'utils/mentionHelper';
import ChatMessageActions from './ChatMessageActions';
import ChatMessageEditBox from './ChatMessageEditBox';


function ChatMessage({ message, onDelete }) {
  const [currentMessage, setCurrentMessage] = useState(message);
  const { user: { _id: userId } } = useAuth();
  const { lastMessage } = useGroupsContext();
  const isOwner = userId.toString() === currentMessage.sentBy?._id.toString();
  const [editing, enableEdit, disableEdit] = useToggleState(false);

  useEffect(() => {
    setCurrentMessage(message);
  }, [message]);

  useEffect(() => {
    if (lastMessage?.type === webSocketType.UPDATE_MESSAGE && 
        lastMessage.data.messageId === message._id) {
      setCurrentMessage(prev => ({
        ...prev,
        content: lastMessage.data.content,
        updatedAt: lastMessage.data.createdAt
      }));
      disableEdit();
    }
  }, [lastMessage, message._id, disableEdit]);

  return (
    <Stack direction="row" flex={1} spacing={1}>
      <Avatar src={currentMessage.sentBy?.avatarURL} />
      <Stack flex={1} bgcolor="white" borderRadius={2} p={1}>
        <Stack
          direction="row"
          flex={1}
          alignItems="center"
          justifyContent="space-between"
          flexBasis={34}
        >
          <Typography color={grey[500]} fontWeight={500}>
            {currentMessage.sentBy
              ? `${currentMessage.sentBy.firstName} ${currentMessage.sentBy.lastName}`.trim()
              : 'Unknown'}
          </Typography>
          {isOwner && !editing && (
            <ChatMessageActions 
              message={message} 
              onEdit={enableEdit}
              onDelete={onDelete}
            />
          )}
        </Stack>
        {editing ? (
          <ChatMessageEditBox 
            message={currentMessage} 
            onClose={disableEdit}
            onUpdate={(content) => {
              setCurrentMessage(prev => ({
                ...prev,
                content
              }));
            }} 
          />
        ) : (
          <Typography paragraph>{getMentionText(currentMessage.content)}</Typography>
        )}
        <Stack alignItems="flex-end">
          <Typography variant="caption" fontSize="x-small" color={grey[500]}>
            {moment( currentMessage.createdAt || currentMessage.timestamp ).fromNow()}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ChatMessage;
