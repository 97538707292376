import { Box, Button, LinearProgress, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'hooks/useSnackbar';
import MentionInput from 'components/MentionInput';
import useTaggableUsers from 'hooks/useTaggableUsers';
import {
  useCreateSlideCommentMutation,
  useUpdateSlideCommentMutation
} from 'services/slides';
import useSlideCommentSocket from 'hooks/useSlideCommentSocket';

/**
 * @param {string} slideId
 * @param {{_id: string, content: string}|undefined} comment
 * @param {function(): *} onSuccess
 * @returns {JSX.Element}
 * @constructor
 */
const CommentBox = ({ slideId, comment, onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const taggableUsers = useTaggableUsers({ enableFetch: true });
  const { emitSlideCommentCreated, emitSlideCommentUpdated } =
    useSlideCommentSocket(slideId);
  const [text, setText] = useState('');
  const [sendComment, { isLoading: isCreating }] =
    useCreateSlideCommentMutation();
  const [updateComment, { isLoading: isUpdating }] =
    useUpdateSlideCommentMutation();

  useEffect(() => {
    if (comment) setText(comment.content);
  }, [comment]);

  const handleSubmit = async () => {
    if (isEmpty(text.trim())) return;
    if ((isCreating && !comment) || (comment && isUpdating)) return;
    try {
      const result = comment
        ? await updateComment({
            slideId,
            commentId: comment._id,
            content: text.trim()
          })
        : await sendComment({
            id: slideId,
            content: text.trim()
          });
      if (!comment) {
        emitSlideCommentCreated(result.data);
      } else {
        emitSlideCommentUpdated(result.data);
      }
      if (result.data) {
        setText('');
        onSuccess?.();
      }
    } catch (e) {
      console.warn(e);
      enqueueSnackbar(e.message || 'Something went wrong!', {
        variant: 'error'
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.metaKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Stack spacing={1}>
      <Box>{(isCreating || isUpdating) && <LinearProgress />}</Box>
      <Stack alignItems="flex-start" spacing={1}>
        <MentionInput
          rows={2}
          sx={{ fontSize: '0.85rem' }}
          inputStyles={{ fontSize: '0.85rem' }}
          placeholder="Write a comment..."
          value={text}
          disabled={isCreating || isUpdating}
          onChange={(e) => setText(e.target.value)}
          data={taggableUsers}
          onKeyDown={handleKeyDown}
        />
        <Button
          variant="contained"
          size="small"
          disabled={isEmpty(text.trim()) || isCreating || isUpdating}
          onClick={handleSubmit}
        >
          {comment ? 'Update' : 'Send'}
        </Button>
      </Stack>
    </Stack>
  );
};

export default CommentBox;
