import { Box, Divider, Stack } from '@mui/material';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { v4 as uuidv4 } from 'uuid';
import axios from '../../../../services/axios';
import CommentBox from './CommentBox';
import CommentHeader from './CommentHeader';
import CommentList from './CommentList';

const GPTUser = {
  id: 9999999999999999,
  firstName: 'Tiby',
  lastName: '',
  avatar: ''
};
const googleApiUrl = process.env.REACT_APP_GOOGLE_CLOUD_STORAGE;

export const ChatGPTComment = ({ slideId, groupId }) => {
  const [comment, setComment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [, setMessageHistory] = useState([]);
  const [data, setData] = useState([]);
  const { user, token } = useAuth();
  const [socketUrl, ] = useState(
    process.env.REACT_APP_WEBSOCKET_API + '/chatgpt/' + groupId + '?token=' + token
  );

  const { lastMessage } = useWebSocket(socketUrl, {
    onOpen: () => {
      console.log('opened');
    },
    onMessage: (message) => {
      const messageData = JSON.parse(message.data);
      if (messageData.type === 'milly_messages') {
        const incomingData = messageData.data.content
          .map((msg) => ({
            id: msg.id,
            content: msg.content,
            file: msg.file,
            createdBy: msg.role === 'assistant' ? GPTUser : user,
            createdAt: new Date(msg.created_at * 1000)
          }))
          .reverse();

        setIsLoading(false);
        setData((prevData) => [...prevData, ...incomingData]);
      } else if (messageData.type === 'chatgpt_message') {
        setIsLoading(false);
        console.log(messageData);
        setData([
          ...data,
          {
            id: uuidv4(),
            content: messageData.data.content,
            createdBy: GPTUser,
            createdAt: new Date()
          }
        ]);
      }
    }
  });

  const chatGPTMessage = ({ content, file, slideId, groupId, isBiomarkerContext }) => {
    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    const formData = new FormData();
    formData.append('content', content);
    formData.append('file', file);
    formData.append('slideId', slideId);
    formData.append('groupId', groupId);
    formData.append('isBiomarkerContext', isBiomarkerContext);
    setIsLoading(true);
    axios
      .post('/chatgpt/', formData, {
        headers: headers
      })
      .then(async (response) => {})
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage));
    }
  }, [lastMessage, setMessageHistory]);

  const onSubmit = (content, file, isBiomarkerContext, callback) => {
    chatGPTMessage({ content, file, slideId, groupId, isBiomarkerContext });
    let fileObject = null;
    if (file !== null) {
      fileObject = {
        fileName: file.name,
        fileUrl: `${googleApiUrl}/local-dp-storage/uploads/chat_attachment/${user.email}/${file.name}`
      };
    }
    setData([
      ...data,
      {
        id: uuidv4(),
        content: content,
        createdBy: user,
        file: fileObject,
        createdAt: new Date()
      }
    ]);
    callback && callback();
  };

  const onDelete = (id, callback) => {
    setTimeout(() => {
      setData(data.filter((comment) => comment.id !== id));
      callback && callback();
    }, 1000);
  };

  const onEdit = (id) => () => {
    const selectedComment = data.find((comment) => comment.id === id);
    setComment(selectedComment);
  };

  const onCancelEdit = () => {
    setComment(null);
  };

  const onPushData = (list) => {
    setData([...data, ...list]);
  };

  return (
    <Stack
      sx={{
        overflowY: 'auto',
        height: '100%'
      }}
      spacing={1}
      p={1}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          width: '100%',
          position: 'relative',
          height: '100%',
          overflow: 'hidden'
        }}
        border={1}
        borderColor="grey.500"
      >
        <CommentHeader onPushData={onPushData} />
        <CommentList
          onDelete={onDelete}
          isLoading={isLoading}
          editId={comment?.id}
          onEdit={onEdit}
          onCancelEdit={onCancelEdit}
          data={data}
        />
        <Divider
          sx={{
            marginTop: 'auto'
          }}
        />
        <CommentBox comment={comment?.content} onSubmit={onSubmit} />
      </Box>
    </Stack>
  );
};
