import AddBoxIcon from '@mui/icons-material/AddBox';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, Grid, Popover, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import NewGroupDialog from './NewGroupDialog';

function Toolbar({ expanded, onExpand, onCollapse }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    handleClose();
    setOpenDialog(true);
  };
  const handleCloseDialog = () => setOpenDialog(false);

  const iconExpand = useMemo(
    () => (
      <IconButton onClick={onExpand}>
        <ChevronRightIcon />
      </IconButton>
    ),
    [onExpand]
  );

  const open = Boolean(anchorEl);

  return (
    <Grid container mb={2}>
      <Grid
        item
        flex={1}
        sx={{
          transition: 'all 95ms linear 120ms',
          maxWidth: expanded ? '100%' : '0px',
          overflow: 'hidden'
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          textTransform="uppercase"
        >
          Groups
        </Typography>
      </Grid>
      <Grid item flex={expanded ? 0 : 1} flexDirection="row">
        <Box
          sx={{
            maxWidth: expanded ? '100%' : '0px',
            maxHeight: expanded ? '100%' : '0px',
            overflow: 'hidden',
            transition: 'all 50ms linear 0ms'
          }}
        >
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        </Box>
        {!expanded && <Stack alignItems="center">{iconExpand}</Stack>}
      </Grid>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Button
          onClick={handleOpenDialog}
          variant="outlined"
          startIcon={<AddBoxIcon style={{ fontSize: 42 }} />}
        >
          <Stack alignItems="flex-start">
            <Typography variant="button" textTransform="uppercase">
              New group
            </Typography>
            <Typography variant="caption" textTransform="none">
              Chat where everyone can write
            </Typography>
          </Stack>
        </Button>
      </Popover>
      {openDialog && (
        <NewGroupDialog open={openDialog} onClose={handleCloseDialog} />
      )}
    </Grid>
  );
}

export default Toolbar;
