import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useWS } from 'contexts/WebSocketContext';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { ROLES } from '../../../constants';
import { useGroupsContext } from '../../../contexts/GroupsContext';
import { useAuth } from '../../../hooks/useAuth';
import useToggleState from '../../../hooks/useToggleState';
import { stringToColor } from '../../../utils/avatar';
import EditGroupDialog from './EditGroupDialog';
import { useNavigate } from 'react-router-dom';

function GroupDetailHeader({ group }) {
  const [editGroup, openEditDialog, closeEditDialog] = useToggleState(null);
  const {
    user: { _id, role }
  } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [leaveConfirmOpen, setLeaveConfirmOpen] = useState(false);
  const { updateGroup } = useWS();
  const { enqueueSnackbar } = useSnackbar();
  const { setGroups } = useGroupsContext();
  const canEdit = role === ROLES.SUPER_ADMIN || group.managedBy._id === _id;
  const navigate = useNavigate();

  function handleOpenEditDialog() {
    openEditDialog?.(group);
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLeaveGroup = async () => {
    try {
      updateGroup( {
        ...group,
        id: group._id,
        members: group.members
          .filter(member => member._id !== _id)
          .map(member => member._id)
      });
      setGroups(prevGroups => {
        const updatedGroups = prevGroups.filter(g => g._id !== group._id);
          navigate('/groups');
        return updatedGroups;
      });
      setLeaveConfirmOpen(false);
      enqueueSnackbar('You have left the group', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message || 'Something went wrong!', {
        variant: 'error'
      });
    }
  };

  if (!group) return null;

  return (
    <Card
      variant="outlined"
      sx={{
        borderLeft: 'none',
        borderRight: 'none',
        backgroundColor: grey[200]
      }}
    >
      <CardContent>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar
            sx={{ 
              bgcolor: stringToColor(group.name),
              width: 40,
              height: 40
            }}
          >
            {group.name.charAt(0).toUpperCase()}
          </Avatar>
          <Stack flex={1} justifyContent="center">
            <Typography variant="h6" pb={0}>
              {group.name}
            </Typography>
            {group.description && (
              <Typography variant="body2" color={grey[700]} sx={{ mb: 0.5 }}>
                {group.description}
              </Typography>
            )}
            <Typography variant="caption" color={grey[600]} lineHeight={1}>
              {group.members?.length + 1 ?? 1} members
            </Typography>
            <Typography variant="caption" color={grey[800]}>
              {group.online?.length ?? 0} online
            </Typography>
          </Stack>
          <Stack direction="row">
            {/* {canEdit && (
              <IconButton onClick={handleOpenInviteDialog}>
                <PersonAddAlt1Icon />
              </IconButton>
            )} */}
            {canEdit && <IconButton onClick={handleOpenEditDialog}>
              <MoreVertIcon />
            </IconButton>}
            {!canEdit && (
              <>
                <IconButton onClick={handleMenuOpen}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setLeaveConfirmOpen(true);
                    }}
                  >
                    Leave the group
                  </MenuItem>
                </Menu>
              </>
            )}
          </Stack>
        </Stack>
      </CardContent>
      {editGroup && (
        <EditGroupDialog
          editable={canEdit}
          group={editGroup}
          open
          onClose={closeEditDialog}
        />
      )}
      {leaveConfirmOpen && (
        <Dialog
          open={leaveConfirmOpen}
          onClose={() => setLeaveConfirmOpen(false)}
        >
          <DialogTitle>Leave Group</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to leave this group?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setLeaveConfirmOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleLeaveGroup} color="error" variant="contained">
              Leave
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* //* Not necessary because this function is included on EditGroupDialog  */}
      {/* <InviteMemberDialog
        group={inviteMemberFlag}
        open={!!inviteMemberFlag}
        onClose={closeEditDialog}
      /> */}
    </Card>
  );
}

export default GroupDetailHeader;
