import { DndContext } from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import { SlideViewerContext } from 'hooks/useSlideViewerContext';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useAnnotationSocket from '../../../../hooks/useAnnotationSocket';
import { useChangeAnnotationGroupMutation } from '../../../../services/annotation';
import AnnotationGroup from './AnnotationGroup';

const AnnotationDetails = ( { hidden, handleDrawerOpen, refetchGroup, isMCDFile } ) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ searchParams ] = useSearchParams();
  const annotationId = searchParams.get( 'annotation' );
  const [ state, dispatch ] = useContext( SlideViewerContext );
  const [ open, setOpen ] = useState( false );
  const [ changeAnnotationGroup, { isLoading } ] =
    useChangeAnnotationGroupMutation( {
      fixedCacheKey: 'changeAnnotationGroup'
    } );
  const { emitAnnotationUpserted } = useAnnotationSocket(
    isMCDFile ? null : state.slide._id,
    dispatch,
    state.anno
  );

  const annotationGroups = useMemo( () => {
    if ( !state.slide || !state.slide.groups ) return [];

    const calculateGroupCount = ( group ) => {
      const annotations = state.filteredAnnotations || state.annotations;
      return annotations.filter( anno =>
        anno.group === group.id &&
        anno.stroke?.toLowerCase() === group.Color?.toLowerCase()
      ).length;
    };

    let groups = [ ...state.slide.groups ].map( group => ( {
      ...group,
      Count: calculateGroupCount( group )
    } ) );

    //* If filtered annotations are present, only display groups that contain those annotations. If filtered annotations are present it's because the slide is an MCD file.
    if (Array.isArray(state.filteredAnnotations)) {
      groups = groups.filter(group => group.Count > 0);
    }
    return groups.sort( ( a, b ) => b.Count - a.Count );
  }, [ state.slide.groups, state.annotations, state.filteredAnnotations ] );

  useEffect( () => {
    if ( hidden ) {
      setOpen( false );
    } else if ( annotationId ) {
      setOpen( true );
    }
  }, [ hidden, annotationId ] );

  const handleClickArrow = () => {
    setOpen( !open );
    if ( hidden ) handleDrawerOpen();
  };

  const handleDragEnd = ( event ) => {
    const stroke = get( event, 'active.data.current.stroke' );
    const groupColor = get( event, 'over.data.current.groupColor' );
    if ( !groupColor || stroke === groupColor ) {
      console.info( 'No change in annotation group' );
      return;
    }
    const annoId = get( event, 'active.data.current.id' );
    // update annotation group via new stroke color
    changeAnnotationGroup( {
      annotationId: annoId,
      groupColor
    } )
      .unwrap()
      .then( ( res ) => {
        dispatch( {
          type: 'UPDATE_ANNOTATION',
          payload: {
            ...res.annotation,
            _refreshGroups: true
          }
        } );
        refetchGroup();
        enqueueSnackbar(
          'Changed annotation group successfully, syncing now...',
          {
            variant: 'success'
          }
        );
        setTimeout( () => {
          emitAnnotationUpserted(
            {
              ...res,
              _isSingleUpdated: true,
              _refreshGroups: true
            },
            true
          );
        }, 500 );
      } )
      .catch( ( e ) => {
        console.error( e );
        enqueueSnackbar( 'Error changing annotation group', {
          variant: 'error'
        } );
      } );
  };

  const renderSkeletons = ( count = 1 ) => {
    return Array.from( { length: count } ).map( ( _, index ) => (
      <Skeleton animation="wave" height={64} key={index} />
    ) );
  };

  return (
    <List>
      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          onClick={() => handleClickArrow()}
          sx={{
            minHeight: 48,
            justifyContent: !hidden ? 'initial' : 'center',
            px: 2.5
          }}
        >
          {hidden ? (
            <Tooltip title="Annotation Details" placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !hidden ? 3 : 'auto',
                  justifyContent: 'center'
                }}
              >
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </ListItemIcon>
            </Tooltip>
          ) : (
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: !hidden ? 3 : 'auto',
                justifyContent: 'center'
              }}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </ListItemIcon>
          )}
          <ListItemText
            primary={'Annotation Details'}
            sx={{
              opacity: hidden ? 0 : 1,
              '& .MuiTypography-root': { fontWeight: '700' }
            }}
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit={!annotationId}>
        <Box sx={{ padding: '8px 20px' }}>
          {annotationGroups.length ? (
            <DndContext
              onDragEnd={handleDragEnd}
              modifiers={[ restrictToWindowEdges ]}
            >
              {isLoading ? (
                renderSkeletons( annotationGroups.length )
              ) : (
                <>
                  {isMCDFile && annotationGroups?.map( ( group, index ) => (
                    <AnnotationGroup group={group} index={index} key={index} />
                  ) )}
                  {!isMCDFile && state.slide.groups.map( ( group, index ) => (
                    <AnnotationGroup group={group} index={index} key={index} />
                  ) )}
                </>
              )}
            </DndContext>
          ) : (
            'No annotation found'
          )}
        </Box>
      </Collapse>
    </List>
  );
};
export default AnnotationDetails;
